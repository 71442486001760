import { PAGE } from '~/constants/router'
import { TYPE_METHOD } from '~/constants/common'
import { PATH_GAME_URL, PATH_GAME_MB_URL, PATH_IMAGES } from '~/constants/path'

export const GAME_PAGE_URL = {
  home: `${PAGE.GAMES}?type=${TYPE_METHOD.ALL}`,
  ingame: `${PAGE.GAMES}?type=${TYPE_METHOD.IN_GAME}&provider=all`,
  fishing: `${PAGE.GAMES}?type=${TYPE_METHOD.FISHING}&provider=all`,
  nohu: `${PAGE.GAMES}?type=${TYPE_METHOD.JACKPOT}&provider=all`,
  slots: `${PAGE.GAMES}?type=${TYPE_METHOD.SLOTS}&provider=all`,
  card_game: `${PAGE.GAMES}?type=${TYPE_METHOD.GAME_BAI}&provider=all`,
  lotte: `${PAGE.GAMES}?type=${TYPE_METHOD.LODE}&provider=all`,
  tables: `${PAGE.GAMES}?type=${TYPE_METHOD.TABLES}&provider=all`,
  keno: `${PAGE.GAMES}?type=${TYPE_METHOD.LOTTERY}&provider=all`,
  quick_game: `${PAGE.GAMES}?type=${TYPE_METHOD.INSTANCE_GAME}&provider=all`,
  live_casino: `${PAGE.CASINO}?type=${TYPE_METHOD.EVO}`,
  live_casino_evo: `${PAGE.CASINO}?type=${TYPE_METHOD.EVO}`,
  live_casino_vivo: `${PAGE.CASINO}?type=${TYPE_METHOD.VIVO}`,
  live_casino_ebet: `${PAGE.CASINO}?type=${TYPE_METHOD.EBET}`,
  live_casino_ezugi: `${PAGE.CASINO}?type=${TYPE_METHOD.EZUGI}`,
  live_casino_pragmatic: `${PAGE.CASINO}?type=${TYPE_METHOD.PRAGMATIC}`,
  live_casino_n2_live: `${PAGE.CASINO}?type=${TYPE_METHOD.N2_LIVE}`,
  live_casino_microgaming: `${PAGE.CASINO}?type=${TYPE_METHOD.MICROGAMING}`,
  live_casino_hogaming: `${PAGE.CASINO}?type=${TYPE_METHOD.HOGAMING}`,
  all: `${PAGE.CASINO}`,
  livestream: `${PAGE.CASINO}?type=livestream&partner=all`,
  taixiu: `${PAGE.CASINO}?type=taixiu&partner=all`,
  xocdia: `${PAGE.CASINO}?type=xocdia&partner=all`,
  baucua: `${PAGE.CASINO}/bau-cua`,
  baccarat: `${PAGE.CASINO}/baccarat`,
  blackjack: `${PAGE.CASINO}/blackjack`,
  sicbo: `${PAGE.CASINO}?type=sicbo&partner=all`,
  roulette: `${PAGE.CASINO}/roulette`,
  lottery: `${PAGE.CASINO}/tv-show`,
  others: `${PAGE.CASINO}/others`,
  poker: `${PAGE.CASINO}/poker`,
  dragontiger: `${PAGE.CASINO}?type=dragontiger&partner=all`,
  bullbull: `${PAGE.CASINO}?type=bullbull&partner=all`
}

export const PROMOTION_URL = {
  olympic_2024: `${PAGE.PROMOTION}/gio-vang-dat-cuoc-bong-da-tai-da88-thuong-100K`,
  promotion_158: `${PAGE.PROMOTION}/hoan-tra-the-thao-158-moi-ngay-tai-da88`,
  refund: `${PAGE.PROMOTION}/hoan-tien-ve-thua-dau-ngay-1-trieu-tai-da88`,
  promotion_05: `${PAGE.PROMOTION}/can-quet-game-bai-doi-thuong-da88-hoan-tra-bat-tan`,
  funding: `${PAGE.PROMOTION}/da88-cap-von-3-lan-tien-nap`,
  euro2024: `${PAGE.PROMOTION}/cuoc-bong-da-euro-nhan-thuong-bat-ngo-tu-da88`,
  copa2024: `${PAGE.PROMOTION}/thuong-100K-cuoc-bong-da-copa-america-tai-da88-moi-ngay`
}

export const DISPLAY_TYPE = {
  all: 25,
  tables: 2,
  slots: 3,
  jackpot: 24,
  in_game: 5,
  lottery: 7,
  lo_de: 8,
  fishing: 9,
  instance_game: 14,
  game_bai: 15,
  othergame: 19
}

export const TITLE_GAME = {
  all: 'Chợ Games',
  tables: 'Table Games',
  slots: 'Slots',
  jackpot: 'Nổ Hũ',
  in_game: 'INGAME',
  lottery: 'Quay số',
  lo_de: 'Lô Đề',
  fishing: 'Bắn Cá',
  instance_game: 'Games Nhanh',
  game_bai: 'Games Bài',
  othergame: 'Games Khác'
}

export const SORT = [
  {
    nameMB: 'Mặc Định',
    name: 'Mặc định',
    icon: `${PATH_GAME_URL}icon-sort-default.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-default-mb.webp`,
    type: 'default',
    typeUrl: '',
    isShow: true
  },
  {
    nameMB: 'Game Mới',
    name: 'Game mới',
    icon: `${PATH_GAME_URL}icon-sort-new.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-new-mb.webp`,
    type: 'NEW_GAME',
    typeUrl: 'game-moi',
    isShow: true
  },
  {
    nameMB: 'Chơi Nhiều',
    name: 'Chơi nhiều',
    icon: `${PATH_GAME_URL}icon-sort-player-mb.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-player-mb.svg`,
    type: 'TOP_USER_BET',
    typeUrl: 'game-duoc-nhieu-nguoi-choi',
    isShow: true
  },
  {
    nameMB: 'Game Hot',
    name: 'Game Hot',
    icon: `${PATH_GAME_URL}icon-sort-hot.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-hot-mb.webp`,
    type: 'HOT_GAME',
    typeUrl: 'game-dang-hot',
    isShow: true
  },
  {
    nameMB: 'Người Mới',
    name: 'Người Mới',
    icon: `${PATH_GAME_URL}icon-sort-newber-mb.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-newber-mb.svg`,
    type: 'FOR_NEWBER',
    typeUrl: 'game-cho-nguoi-moi',
    isShow: true
  },
  {
    nameMB: 'Cược Nhiều',
    name: 'Cược nhiều',
    icon: `${PATH_GAME_URL}icon-sort-bets.svg`,
    iconMB: `${PATH_GAME_MB_URL}icon-sort-bets-mb.webp`,
    type: 'TOP_BET',
    typeUrl: 'game-duoc-cuoc-nhieu',
    isShow: true
  }
]

export const EMPTY_CONTENT = [
  {
    icon: `${PATH_IMAGES}icon-empty.webp`,
    text: 'Đang cập nhật dữ liệu'
  }
]

export const PACKAGE_ID_BLOCK_GAME = [2, 3, 4, 5, 6, 7]
